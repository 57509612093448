import { createContext, useState } from "react";
import { ILoadingContext, ILoadingProps } from "./loading.interface";

const loadingInitValues: ILoadingContext = {
    isLoading: true,
    setIsLoading: () => {},
};

export const LoadingContext = createContext<ILoadingContext>(loadingInitValues);

const LoadingProvider = ({ children }: ILoadingProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);

    return (
        <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
            {children}
        </LoadingContext.Provider>
    );
};

export default LoadingProvider;
