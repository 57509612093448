import { useContext, useEffect } from "react";
import GlobalAnimation from "../../components/GlobalAnimation/GlobalAnimation";
import { APP_CONFIGS } from "../../shared/constants";
import { LayoutContext } from "../../shared/providers/layout/layout.provider";
import { LoadingContext } from "../../shared/providers/loading/loading.provider";

function Contacts() {
    const { setNavPosition } = useContext(LayoutContext);
    const { setIsLoading } = useContext(LoadingContext);

    useEffect(() => {
        setNavPosition("right");
    }, []);

    return (
        <GlobalAnimation>
            <div className="page-contacts">
                <div className="container-fluid gx-0">
                    <div className="row gx-0">
                        <figure className="page_figure col-12 col-md-6">
                            <img
                                onLoad={() => {
                                    setIsLoading(false);
                                }}
                                loading="lazy"
                                width="900"
                                height="1600"
                                src={APP_CONFIGS.pages.HELP.image}
                                alt={APP_CONFIGS.pages.HELP.image_alt}
                            />
                        </figure>
                    </div>
                </div>
                <div className="container page_content">
                    <div className="row h-100">
                        <div className="col-12 col-md-6 offset-md-6 pt-12 pt-md-5 d-flex flex-column gap-1 gap-md-11 page_decoration is_right pe-lg-0">
                            <h1 className="text-h2-bd-md text-h3-bd text-uppercase">
                                {APP_CONFIGS.pages.HELP.title}
                            </h1>
                            <p className="text-bd2-md">
                                Para quaisquer dúvidas que possa ter:
                            </p>
                            <p className="text-bd2-md">
                                Email: <a href="mailto:creaveirochen@gmail.com" className="text-bd2-rg">craveirochen@gmail.com</a>
                                <br/>
                                <br/>
                                Contacto dos noivos: 
                                <br/>
                                <a href="tel:+351915848101" className="text-bd2-rg">+351 915 848 101 (Mariana - Noiva)</a>
                                <br/>
                                <a href="tel:+351936099274" className="text-bd2-rg">+351 936 099 274 (Duarte - Noivo)</a>
                                <br/>
                                <br/>
                                Contactos adicionais: 
                                <br/>
                                <a href="tel:+351912374298" className="text-bd2-rg">+351 912 374 298 (Diana - Irmã da Noiva)</a>
                                <br/>
                                <a href="tel:+351938222800" className="text-bd2-rg">+351 938 222 800 (Diogo - Irmão do Noivo)</a>
                            </p> 
                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <h2 className="text-h4-md mb-1">Igreja</h2>
                                    
                                    <p className="text-bd2-md d-flex align-items-center gap-1">
                                        <i
                                            className={`icon icon-20 icon-map`}
                                        ></i>
                                        <a
                                            rel="noreferrer"
                                            href="https://maps.app.goo.gl/95Rf1nvcMtoYKuKTA"
                                            target="_blank"
                                        >
                                            Igreja Matriz de São Lucas
                                        </a>
                                    </p>
                                </div>
                                <div className="col-12 col-lg-6 mt-2 mt-lg-0">
                                    <h2 className="text-h4-md mb-1">Quinta</h2>
                                    <p className="text-bd2-md d-flex align-items-center gap-1">
                                        <i
                                            className={`icon icon-20 icon-map`}
                                        ></i>
                                        <a
                                            rel="noreferrer"
                                            href="https://maps.app.goo.gl/MntmBB3ZDBNFj4189"
                                            target="_blank"
                                        >
                                            Quinta dos Rouxinóis
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </GlobalAnimation>
    );
}

export default Contacts;
