export const APP_CONFIGS = {
    title: "Craveiro Chen",
    pages: {
        HOME: {
            title: "Home",
            icon: "",
            path: "/",
            image: "/assets/imgs/2.jpg",
            image_alt: "Fotografia dos noivos",
        },
        CHECKIN: {
            path: "/checkin",
            title: "Check In",
            image: "/assets/imgs/3.JPG",
            image_alt: "Fotografia dos noivos",
        },
        ITINERARY: {
            path: "/history",
            title: "História",
            image: "/assets/imgs/4.JPG",
            image_alt: "Fotografia dos noivos",
        },
        HELP: {
            path: "/contacts",
            title: "Contactos",
            image: "/assets/imgs/3.JPG",
            image_alt: "Fotografia dos noivos",
        },
    },
};

// export const CONTENT_BASE_URL = "https://script.google.com/macros/s/AKfycbxRLFkai0FyiZRZjI0xF7UfFWRaWntxlSolMMrKi5ThI2Hg2xdfrd4EhtcxzEAr0Jgl/exec";
export const CONTENT_BASE_URL =
    "https://docs.google.com/forms/u/0/d/e/1FAIpQLScGdKHGxiYEl8K_Dgy3_3sA-eLApsFECc7BIFYgKfoptxuJew/formResponse";
