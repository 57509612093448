import { useContext, useEffect } from "react";
import GlobalAnimation from "../../components/GlobalAnimation/GlobalAnimation";
import Link from "../../components/Link/Link";
import { APP_CONFIGS } from "../../shared/constants";
import { routes } from "../../shared/router/routes";
import { LayoutContext } from "../../shared/providers/layout/layout.provider";
import { LoadingContext } from "../../shared/providers/loading/loading.provider";

function Home() {
    const { setNavPosition } = useContext(LayoutContext);
    const { setIsLoading } = useContext(LoadingContext);

    useEffect(() => {
        setNavPosition("left");
    }, []);

    return (
        <GlobalAnimation>
            <div className="page-home container">
                <div className="row h-100">
                    <div className="col-12 col-md-6 d-flex justify-content-center align-items-center flex-column page_decoration">
                        <div className="d-flex flex-column gap-1 gap-md-2 me-1 align-items-end page_content">
                            <h1 className="page_title text-headline-bd text-headline-bd-lg mx-auto ms-md-auto">
                                <span>Duarte</span>
                                <span>&</span>
                                <span>Mariana</span>
                            </h1>
                            <span className="date">15 de Agosto, 2025</span>
                            <Link
                                variant="solid"
                                to={routes.CHECKIN.path}
                                label="Check In"
                            />
                        </div>
                    </div>
                    <figure className="col-12 col-md-6 page_figure">
                        <img
                            onLoad={() => setIsLoading(false)}
                            loading="lazy"
                            width="900"
                            height="1600"
                            src={APP_CONFIGS.pages.HOME.image}
                            alt={APP_CONFIGS.pages.HOME.image_alt}
                        />
                    </figure>
                </div>
            </div>
        </GlobalAnimation>
    );
}

export default Home;
