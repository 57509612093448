import { ISelectOption } from "../../components/Forms/Select/Select.interface";
import { CONTENT_BASE_URL } from "../constants";
import { IGuest } from "../interfaces/guests.interface";
import { IRequest } from "../interfaces/requests.interface";

export const sendGuest = async (guest: IGuest): Promise<IRequest<null>> => {
    const formdata = new FormData();
    formdata.append("entry.1787281768", guest.name);
    formdata.append("entry.566312909", guest.ageGroup);
    formdata.append("entry.1549087346", guest.foodRestrictions);
    
    try {
        await fetch(CONTENT_BASE_URL, {
            method: "POST",
            body: formdata,
            mode: "no-cors",
        });

        return { success: true, data: null };
    } catch (e: any) {
        console.log(e.message);
        return { success: false, data: null };
    }
};
