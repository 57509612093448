import { useContext } from "react";
import Navbar from "../Navbar/Navbar";
import { IHeaderProps } from "./Header.interface";
import { LayoutContext } from "../../shared/providers/layout/layout.provider";

function Header(props: IHeaderProps) {
  const { navPosition } = useContext(LayoutContext);

  const navPos = (position: string) => {
    switch (position) {
      case "left":
        return "col-12 col-md-6";
      case "right":
        return "col-12 col-md-6 col-lg-5 offset-md-6 offset-lg-7 justify-content-md-start";
      case "center":
        return "col-12 col-md-6 offset-md-3";
      default:
        return position;
    }
  };

  return (
    <header className="header">
      <div className="container py-1 gx-0 gx-sm-1">
        <div className="row gx-0 gx-md-4">
          <div
            className={`${navPos(navPosition)} d-flex justify-content-center`}
          >
            <Navbar />
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
