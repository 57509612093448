import { createBrowserRouter } from "react-router-dom";
import { routes } from "./routes";
import Home from "../../pages/home/Home";
import Checkin from "../../pages/checkin/Checkin";
import Itinerary from "../../pages/itinerary/Itinerary";
import Index from "../../pages/index/Index";
import Contacts from "../../pages/contacts/Contacts";

const router = createBrowserRouter([
  {
    path: routes.HOME.path,
    element: <Index />,
    children: [
      {
        path: routes.HOME.path,
        element: <Home />,
      },
      {
        path: routes.CHECKIN.path,
        element: <Checkin />,
      },
      {
        path: routes.ITINERARY.path,
        element: <Itinerary />,
      },
      {
        path: routes.HELP.path,
        element: <Contacts />,
      },
      {
        path: "*",
        element: <p>404</p>,
      },
    ],
  },
]);

export default router;
