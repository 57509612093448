import React from "react";
import ReactDOM from "react-dom/client";
import "./shared/styles/index.scss";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider } from "react-router-dom";
import router from "./shared/router/router";
import LoadingProvider from "./shared/providers/loading/loading.provider";
import { QueryClient, QueryClientProvider } from "react-query";
import LayoutProvider from "./shared/providers/layout/layout.provider";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
);

const queryClient = new QueryClient();

root.render(
    <React.StrictMode>
        <LayoutProvider>
            <QueryClientProvider client={queryClient}>
                <LoadingProvider>
                    <RouterProvider router={router} />
                </LoadingProvider>
            </QueryClientProvider>
        </LayoutProvider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
