import { useContext, useEffect, useRef, useState } from "react";
import GlobalAnimation from "../../components/GlobalAnimation/GlobalAnimation";
import { APP_CONFIGS } from "../../shared/constants";
import { LayoutContext } from "../../shared/providers/layout/layout.provider";
import { LoadingContext } from "../../shared/providers/loading/loading.provider";
import Button from "../../components/Button/Button";
import Modal from "../../components/Modal/Modal";

function Itinerary() {
    const { setNavPosition } = useContext(LayoutContext);
    const { setIsLoading } = useContext(LoadingContext);
    const [isOpen, setIsOpen] = useState(false);
    const sectionsEl = useRef<HTMLDivElement>(null);
    const [section, setSection] = useState<any>();

    useEffect(() => {
        setNavPosition(
            "col-12 col-md-8 col-lg-8 offset-md-4 offset-lg-4 justify-content-md-start",
        );
    }, []);

    const sections = [
        {
            title: "Campismo em Castelo do Bode",
            content: (
                <>
                    <p className="item_description text-bd3-rg mt-02">
                        Este foi o local da nossa primeira aventura: Acampar em
                        Fevereiro! No nosso primeiro campismo juntos, surgiu-nos
                        a brilhante ideia de mergulhar todos os meses, o ano
                        inteiro. Quem disse que era uma boa ideia? Naquele ano,
                        especialmente no inverno do Atlântico, congelámos
                        juntos, mas pelo menos rimos muito e colecionámos
                        tremeliques para contar!
                    </p>
                </>
            ),
            image: {
                url: "/assets/imgs/castelo_bode.png",
                alt: "Campismo em Castelo do Bode",
            },
        },
        {
            title: "Carnaval",
            content: (
                <>
                    <p className="item_description text-bd3-rg mt-02">
                    Ah, parte do batismo de entrada na família é a Cultura Chinesa, tanto como o Carnaval de Torres Vedras! Imaginem misturar os dois! Foi especialmente convincente sempre que o Duarte fingia que sabia falar imenso chinês: toda a gente acreditava! Ainda assim, a melhor parte foi tentar explicar aos mais curiosos que sim, o Duarte é meio chinês, mas que isso não nos dá descontos na loja do chinês.
                    </p>
                </>
            ),
            image: {
                url: "/assets/imgs/carnaval.png",
                alt: "Carnaval",
            },
        },
        {
            title: "Carcamping na Lagoa de Óbidos",
            content: (
                <>
                    <p className="item_description text-bd3-rg mt-02">
                    Porque acampar era pouco, decidimos fazer upgrade ao campismo e o Duarte supreendeu a Mariana com uma transformação no C3! Transformar o Citroën numa autocaravana foi uma verdadeira aventura, mas dormir nele foi uma ainda maior! Com criatividade e muito improviso, criámos o nosso pequeníssimo refúgio sobre rodas e partimos para o meio do nada. A parte da pesca, bom… Pelo menos ficámos com a memória do peixe imaginário que quase apanhámos!
                    </p>
                </>
            ),
            image: {
                url: "/assets/imgs/carcamping_obidos.png",
                alt: "Carcamping na Lagoa de Óbidos",
            },
        },
        {
            title: "Carcamping em Montargil",
            content: (
                <>
                    <p className="item_description text-bd3-rg mt-02">
                    Montargil foi onde nos tornámos oficialmente campistas profissionais de Citroën C3! Levámos o campismo selvagem a um novo patamar, criámos o nosso spot junto à barragem. Montámos o acampamento com uma organização digna de um palácio, transformámos o carro numa autocaravana premium e no meio do nado fomos felizes só a sermos nós. Foi o primeiro sítio onde conversámos sobre a nossa definição de “ser e estar”. Naquele espaço estávamos só a existir e nisso fomos e somos tão felizes… 
                    </p>
                </>
            ),
            image: {
                url: "/assets/imgs/carcamping_montargil.png",
                alt: "Carcamping em Montargil",
            },
        },
        {
            title: "Carcamping na Serra Nevada",
            content: (
                <>
                    <p className="item_description text-bd3-rg mt-02">
                    Na Serra Nevada, descobrimos o que “roadtrip” em espanhol significa “festa do pijama para carros”! De dia, o carro era uma sauna sobre rodas, e à noite, um congelador ambulante. Mas não estávamos sozinhos - outros aventureiros (com carros ligeiramente maiores) também tentavam sobreviver ao frio. No entanto, em pleno julho, tivemos uma noite belíssima, sob um céu estrelado e um silêncio quase mágico.

                    </p>
                </>
            ),
            image: {
                url: "/assets/imgs/sierra_nevada.png",
                alt: "Serra Nevada",
            },
        },
        {
            title: "Levadas na Madeira",
            content: (
                <>
                    <p className="item_description text-bd3-rg mt-02">
                    Na nossa curta visita à Ilha da Madeira, três coisas marcaram a viagem: a comida—porque a Mariana é fã; as levadas—onde apreciámos a beleza da criação (e o Duarte gosta imenso de contemplar); e, acima de tudo, o nascimento da nossa sobrinha, imortalizado nesta foto tirada logo após recebermos a notícia! Foi uma viagem especial, pois a Madeira tem um significado profundo para a nossa família, e, de alguma forma, conseguimos reunir quase todos num só fim de semana.

                    </p>
                </>
            ),
            image: {
                url: "/assets/imgs/levadas.png",
                alt: "Levadas na Madeira",
            },
        },
        {
            title: "Karts em Lisboa",
            content: (
                <>
                    <p className="item_description text-bd3-rg mt-02">
                    Num domingo aleatório, um de nós decidiu fazer uma surpresa ao outro, levando-o a um sítio que ele/ela ia adorar… Adivinhem quem teve a ideia genial? Claro! O Duarte, convencidíssimo de que um date de karts era a coisa mais romântica do mundo! Entre capacetes a boiar e dores no “pós”, foi um encontro inesperado, divertido e cheio de brincadeiras—perfeito à nossa maneira.
                    </p>
                </>
            ),
            image: {
                url: "/assets/imgs/karts.png",
                alt: "Karts em Lisboa",
            },
        },
        {
            title: "Paraquesimo em Évora",
            content: (
                <>
                    <p className="item_description text-bd3-rg mt-02">
                    Todos os anos, por ser no verão, o aniversário do Duarte é celebrado com uma atividade diferente a dois. A Mariana queria muito partilhá-la com ele, não só porque não era estreante, mas porque sabia que o Duarte iria delirar. O único problema desta surpresa? Não foi surpresa e revelou tudo na noite anterior! A reação de êxtase foi tão grande que nem sabemos como é que ele conseguiu dormir. Este dia ficou marcado como um dos que o Duarte mais sorriu, uma alegria contagiante que nos fez perceber, mais do que nunca, que somos o par perfeito. Afinal, só se estraga uma casa com tanta adrenalina!
                    </p>
                </>
            ),
            image: {
                url: "/assets/imgs/paraquedismo.png",
                alt: "Paraquesimo em Évora",
            },
        },
        {
            title: "Prova de Vinhos",
            content: (
                <>
                    <p className="item_description text-bd3-rg mt-02">
                    Esta foto, com um copo de vinho do Porto, representa todas as provas de vinho que já fizemos juntos! No início, a Mariana ainda se aventurava no vinho tinto, mas agora é sempre a primeira a pedir um copo. Mais curioso ainda? Até no pedido de casamento o vinho teve um papel especial—foi um brinde com o Maria Ana que deu o mote e o impulso para o grande momento!
                    </p>
                </>
            ),
            image: {
                url: "/assets/imgs/prova_vinhos.png",
                alt: "Prova de Vinhos",
            },
        },
        {
            title: "Passeio no Porto",
            content: (
                <>
                    <p className="item_description text-bd3-rg mt-02">
                    Esta foi a nossa primeira viagem juntos ao Porto, onde, entre sobrinhos e amigos de sobrinhos, fizemos babysitting para idades dos 1 aos 10 anos! Visitámos pela primeira vez a Livraria Lello e, para marcar o momento, cada um escolheu um livro que guardamos com carinho. Cumprimos a tradicional rota da Francesinha, mas, para desilusão do Duarte, a sua querida cerveja Nortada esteve em falta! O passeio levou-nos do Porto até à Serra da Estrela, onde o Duarte esperou pela neve com a mesma ansiedade de uma criança à espera do Pai Natal. Mas, tal como muitas crianças na noite de Natal, adormeceu antes do grande momento… e só viu o resultado ao acordar!
                    </p>
                </>
            ),
            image: {
                url: "/assets/imgs/porto.png",
                alt: "Passeio no Porto",
            },
        },
        {
            title: "Hike na Serra da Estrela",
            content: (
                <>
                    <p className="item_description text-bd3-rg mt-02">
                    Numa das nossas aventuras, decidimos acampar num sítio frio… e escolhemos nada menos que a Serra da Estrela em pleno janeiro! Apesar do upgrade com um mini-aquecedor, foi, sem dúvida, a noite de campismo mais gelada que já passámos, até porque choveu tanto que a água decidiu entrar na tenda! Para manter o espírito aventureiro, ainda fizemos um hike, com chá quente a meio para aquecer a alma. Foi uma experiência incrível e, sem dúvida, a repetir… mas talvez da próxima vez num bungalow aquecido e à prova de chuva, pelo menos por cima da cama!
                    </p>
                </>
            ),
            image: {
                url: "/assets/imgs/serra_estrela.png",
                alt: "Hike na Serra da Estrela",
            },
        },
        {
            title: "Snowboard no Canadá",
            content: (
                <>
                    <p className="item_description text-bd3-rg mt-02">
                    Na nossa viagem ao Canadá, fizemos snowboard juntos pela primeira vez. A Mariana, já experiente, deslizava com confiança, enquanto o Duarte, novato, caiu várias vezes nas pistas de iniciação. Curiosamente, ambos, nas pistas maiores, saíram-se muito bem, a diferença foi o rabo dorido do Duarte. No final, a Mariana manteve-se impecável, sem cair, e o Duarte feliz pela aventura!
                    </p>
                </>
            ),
            image: {
                url: "/assets/imgs/snowboard.png",
                alt: "Snowboard no Canadá",
            },
        },
        {
            title: "Cataratas do Niágara",
            content: (
                <>
                    <p className="item_description text-bd3-rg mt-02">
                    Na nossa viagem a Toronto, a primeira fora do continente e a maior que fizémos juntos, explorámos esta parte do continente Americano e tivémos a oportunidade de conhecer a cidade! Mas o que realmente ficará na memória foi a nossa visita às Cataratas do Niágara em pleno fevereiro, com sensação térmica de -37º, uma experiência surreal! O Duarte não parava de mencionar o frio na ponta do nariz, enquanto a Mariana se divertia, já que ele se recusava a usar gorro. Além do frio, vimos uma das maiores e mais imponentes maravilhas do mundo, um lugar memorável.
                    </p>
                </>
            ),
            image: {
                url: "/assets/imgs/cataratas.png",
                alt: "Cataratas do Niágara",
            },
        },
        {
            title: "Viagem a Barcelona",
            content: (
                <>
                    <p className="item_description text-bd3-rg mt-02">
                    Esta viagem começou com uma conversa entre duas primas com álcool a mais no sangue. “Voos baratos? Vamos viajar! Para onde? Barcelona?” - em 5 minutos decidimos ir a Barcelona! Os 5 minutos seguintes foram passados a comprar a viagem e os outros 5 a tentar cancelá-la! Uma viagem aleatória e gastronómica, em resumo, margaritas e paella, muita sangria e mojitos quase de gratuitos na praia. Conhecemos alguns sítios, mas não tão bem quanto gostaríamos, porque achámos que não era preciso reservar nada com antecedência. Bem, pelo menos existe o presunto e as cañas!
                    </p>
                </>
            ),
            image: {
                url: "/assets/imgs/barcelona.png",
                alt: "Viagem a Barcelona",
            },
        },
        {
            title: "Santos Populares",
            content: (
                <>
                    <p className="item_description text-bd3-rg mt-02">
                    Este evento foi uma verdadeira montanha-russa de emoções: alegria, êxtase, medo, pânico, frustração e muita tristeza! Tudo começou com surpresas e diversão, mas acabou com um assalto e o roubo de 4 computadores. Entre perseguir os assaltantes e passar a noite na esquadra a ensinar um polícia a escrever “quechua” para fazermos toda a descrição de tudo o que tínhamos, a experiência marcou o nosso 2023. A única parte positiva? A dor foi partilhada com amigos, que, ao longo dos anos, estiveram ao nosso lado nas dificuldades e com quem esperamos celebrar apenas alegrias no futuro!
                    </p>
                </>
            ),
            image: {
                url: "/assets/imgs/santos.png",
                alt: "Santos Populares",
            },
        },
        {
            title: "Jornada Mundial da Juventude",
            content: (
                <>
                    <p className="item_description text-bd3-rg mt-02">
                    Esta foi uma aventura de vida! Entre full-time job e full-time life, a ter a cara do Duarte estampada num cartaz por todo o país e arredores foi só uma das partes incríveis. Tudo o que fizemos, com tantas horas de trabalho e pouco sono, culminou no evento que terminámos juntos e de mãos dadas. Vivemos a nossa vida na sede da JMJ, no Beato, e no pavilhão Carlos Lopes, onde basicamente comíamos e quase dormíamos! Foi uma prova de fogo para nós como casal. Felizmente, foram muitas as “mãos de Deus” que nos guiaram e, hoje, colhemos frutos dessa semana, encontrando alegria na nossa vida tranquila, entre os escuteiros, catequese e a paróquia. Foi sem dúvida uma experiência marcante!
                    </p>
                </>
            ),
            image: {
                url: "/assets/imgs/jmj.png",
                alt: "Jornada Mundial da Juventude",
            },
        },
        {
            title: "Canyoning em Castro Laboreiro",
            content: (
                <>
                    <p className="item_description text-bd3-rg mt-02">
                    No norte do país, já maravilhados com a beleza do Gerês, tivemos a oportunidade de fazer canyoning em Castro Laboreiro. Entre a destreza e a alegria de partilharmos a experiência, o momento mais marcante foi o salto de 7 metros. Não pelo tamanho do desafio, mas pela forma como nos motivámos mutuamente, pois adoramos contar as histórias do que vivemos juntos. Sempre que saltávamos ou fazíamos rapel, quem ia à frente voltava para verificar se o outro estava bem e aplaudia, dando coragem. Mesmo sendo destemidos, tivemos momentos de insegurança, e foi nesse apoio mútuo que a aventura se tornou ainda mais especial!
                    </p>
                </>
            ),
            image: {
                url: "/assets/imgs/canyoning.png",
                alt: "Canyoning em Castro Laboreiro",
            },
        },
        {
            title: "Snorkeling nas Berlengas",
            content: (
                <>
                    <p className="item_description text-bd3-rg mt-02">
                    A ida às Berlengas no aniversário do Duarte foi realmente marcante. Para organizar uma festa surpresa, a Mariana teve a ideia de levá-lo até lá, aproveitando a oportunidade para passar o dia longe de casa. Tentou encontrar todas as opções possíveis, até acampar nas Berlengas, mas como isso já não era viável, optou por um upgrade para uma viagem de barco e planeou ao pormenor o almoço. Levaram o equipamento de snorkeling e, com um dia lindíssimo e água super nítida, tiveram uma experiência incrível na ilha, entre passeios de barco, caminhadas e mergulhos. No entanto, o que poderia ter sido apenas uma memória encantadora transformou-se em algo ainda mais marcante. Enquanto a Mariana, preocupada, aguardava junto aos pertences no porto, chamou o Duarte para se manter no seu campo de visão, algo que ele ignorou por completo. Quando o barco se aproximou, o Duarte voltou à terra… mas a verdadeira surpresa estava por vir. Sem a Mariana saber, naquele dia o Duarte tinha rezado sobre sua vida e como a entregaria à Mariana. E como sempre acontece nas “Deuscidências”, a surgiu uma pista clara. No fundo do mar, a cerca de 3 metros de profundidade, entre os barcos, o Duarte encontrou o anel de noivado que usaria para fazer o pedido de casamento!
                    </p>
                </>
            ),
            image: {
                url: "/assets/imgs/anel_berlengas.png",
                alt: "Snorkeling nas Berlengas",
            },
        },
        {
            title: "Ilha de Brownsea",
            content: (
                <>
                    <p className="item_description text-bd3-rg mt-02">
                    Brownsea foi o local do primeiro acampamento escutista e também o tema do primeiro acampamento do Duarte como escuteiro (candidato a dirigente!). Foi lá que ele teve a sua primeira experiência de acampamento no contexto escutista, e a alegria de vivê-la no agrupamento foi tão grande que ele guarda com carinho toda a experiência de vida em campo — algo que distingue um escuteiro de um mero campista. Este acampamento também marcou o início de algo muito maior para o Duarte: por amor à Mariana, ele decidiu tornar-se escuteiro e passar a fazer parte de um movimento que tanto a marcou, e que agora marca também a vida dos dois!
                    </p>
                </>
            ),
            image: {
                url: "/assets/imgs/brownsea.png",
                alt: "Ilha de Brownsea",
            },
        },
        {
            title: "Snorkeling no Gerês",
            content: (
                <>
                    <p className="item_description text-bd3-rg mt-02">
                    Estávamos a caminhar pelo Gerês quando a Mariana insistiu que tínhamos de ir a uma cascata menos conhecida. Com o espírito aventureiro de sempre, pegámos nas mochilas com o equipamento de snorkling e seguimos pela natureza na esperança de que o local que a Mariana mencionava fosse realmente interessante. Chegámos lá e o Duarte ficou encantado, nadou e saltou a partir das rochas durante imenso tempo. A Mariana, como é habitual, deu apenas alguns mergulhos e ficou cheia de frio. No entanto, a alegria dela estava em saber que tinha levado o Duarte a um lugar que o faria novamente criança.
                    </p>
                </>
            ),
            image: {
                url: "/assets/imgs/geres.png",
                alt: "Snorkeling no Gerês",
            },
        },
        {
            title: "Hike na Serra de Gredos",
            content: (
                <>
                    <p className="item_description text-bd3-rg mt-02">
                    No nosso segundo ano de aventuras em Espanha, descobrimos que a Sierra de Gredos, é um local incrível e entrou para o nosso top de locais para campismo! Associado ao campismo, fizemos um hike incrível em família, durante o qual queríamos mergulhar em todas as “poças” que pareciam pequenas cascatas, mas o resultado foram fotos péssimas da Mariana, toda molhada e vestida com calções e um blusão de inverno. O que ninguém tinha avisado a Mariana é que o hike passava pelo meio das vacas, e descobrimos também que ela tem muito medo de vacas com cornos! A aventura foi tão longa que ficámos sem água e tivemos de beber da água de um riacho onde, provavelmente, as nossas amigas vacas tinham deixado as suas marcas, mais acima!
                    </p>
                </>
            ),
            image: {
                url: "/assets/imgs/gredos.png",
                alt: "Hike na Serra de Gredos",
            },
        },
        {
            title: "Mergulho nas Berlengas",
            content: (
                <>
                    <p className="item_description text-bd3-rg mt-02">
                    No último aniversário do Duarte enquanto solteiro e para marcar não só as nossas atividades radicais, mas também para voltarmos a um local onde um de nós soube com certezas que queria ficar com o outro para o resto da vida, desta vez decidimos fazer um upgrade e fizemos o nosso batismo de mergulho. Foi um fim-de-semana encantador onde voltámos apenas a ser e estar os dois nas berlengas! E, com a mesma sorte que tínhamos tido havia um ano, encontrámos um mar nítido e um dia incrível. Foi muito giro partilharmos aquele momento e a experiência dentro de água!
                    </p>
                </>
            ),
            image: {
                url: "/assets/imgs/mergulho.png",
                alt: "Mergulho nas Berlengas",
            },
        },
        {
            title: "Acampamento de Verão com a catequese",
            content: (
                <>
                    <p className="item_description text-bd3-rg mt-02">
                    O Duarte e a Mariana, catequistas do 7º e 8º ano, acharam que era uma boa ideia fazer um acampamento de verão… no quintal! Dois dias que pareciam simples, mas que se transformaram numa autêntica maratona de mergulhos na piscina, corridas pelo jardim e muita gritaria – basicamente, voltaram a ser crianças novamente. À noite, o espírito de aventura continuou com cinema ao ar livre. Projetor ligado, miúdos enfiados nos sacos-cama, e eles ali, de olhos pesados, mas com o coração leve. No final, estavam todos exaustos – mas felizes.
                    </p>
                </>
            ),
            image: {
                url: "/assets/imgs/catequese.png",
                alt: "Acampamento de Verão com a catequese",
            },
        },
    ];

    function updateLine() {
        const formatLine = (line: Element, isEven: boolean) => {
            const svg = line.parentElement as HTMLElement;
            const box1 = svg?.previousElementSibling?.querySelector(
                "img",
            ) as HTMLElement;
            const box2 = svg?.nextElementSibling?.querySelector(
                "img",
            ) as HTMLElement;

            if (!box1 || !box2 || !svg) return;

            const circles = svg.querySelectorAll("circle");

            svg.style.width = "100%";
            svg.style.height = `${150 + Math.random() * 50}px`;

            const svgRect = svg.getBoundingClientRect();
            const dim1 = box1.getBoundingClientRect();
            const dim2 = box2.getBoundingClientRect();

            let x1, y1, x2, y2, midX, midY, waveOffset;
            if (isEven) {
                /// Calculate start (bottom-left of box1)
                x1 = dim1.left - svgRect.left;
                y1 = dim1.bottom - svgRect.top + 20;
                y1 = y1 < 20 ? 20 : y1;

                // Calculate end (top-right of box2)
                x2 = dim2.right - svgRect.left;
                y2 = dim2.top - svgRect.top - 20;

                waveOffset = Math.abs(x2 - x1) / -1.8; // Determines how wavy the line is
            } else {
                // Calculate start (bottom-left of box1)
                x1 = dim1.right - svgRect.left;
                y1 = dim1.bottom - svgRect.top + 20;

                // Calculate end (top-right of box2)
                x2 = dim2.left - svgRect.left;
                y2 = dim2.top - svgRect.top - 20;

                waveOffset = Math.abs(x2 - x1) / 2; // Determines how wavy the line is
            }

            midX = (x1 + x2) / 2;
            midY = (y1 + y2) / 2;

            circles[0].setAttribute("cx", `${x1}`);
            circles[0].setAttribute("cy", `${y1}`);
            circles[1].setAttribute("cx", `${x2}`);
            circles[1].setAttribute("cy", `${y2}`);

            const pathData = `
            M ${x1} ${y1}
            Q ${midX - waveOffset} ${midY - 30}, ${midX} ${midY}
            Q ${midX + waveOffset} ${midY + 30}, ${x2} ${y2}
        `;

            line.setAttribute("d", pathData);
        };
        sectionsEl.current
            ?.querySelectorAll("svg path")
            .forEach((line, i) => formatLine(line, (i + 1) % 2 === 0));
    }
    useEffect(() => {
        updateLine();
        window.addEventListener("resize", updateLine);

        return () => window.removeEventListener("resize", updateLine);
    });

    const handleSeeMore = (index: number) => {
        setIsOpen(true);
        setSection(sections[index]);
    };

    return (
        <GlobalAnimation>
            <div className="page-itinerary">
                <div className="container-fluid gx-0">
                    <div className="row gx-0">
                        <figure className="page_figure col-12 col-md-4">
                            <img
                                onLoad={() => {
                                    setIsLoading(false);
                                }}
                                loading="lazy"
                                width="900"
                                height="1600"
                                src={APP_CONFIGS.pages.ITINERARY.image}
                                alt={APP_CONFIGS.pages.ITINERARY.image_alt}
                            />
                        </figure>
                    </div>
                </div>
                <div className="container page_content">
                    <div className="row h-100">
                        <div className="col-12 col-md-8 offset-md-4 pt-12 pt-md-5 d-flex flex-column gap-1 gap-md-11 page_decoration is_mid_right pe-lg-0">
                            <h1 className="text-h2-bd-md text-h3-bd text-uppercase">
                                {APP_CONFIGS.pages.ITINERARY.title}
                            </h1>
                            <p className="text-bd2-md mb-n4">
                                Queremos que façam parte de cada momento do
                                nosso grande dia! Juntem-se a nós para criarmos
                                memórias inesquecíveis!
                            </p>

                            <div ref={sectionsEl} className="sections my-6">
                                {sections.map((section, i) => (
                                    <ItineraryItem
                                        key={i}
                                        section={section}
                                        index={i}
                                        isLast={i === sections.length - 1}
                                        onClick={() => handleSeeMore(i)}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal isOpen={isOpen} setIsOpen={setIsOpen} title={section?.title}>
                <div className="row g-12">
                    <div className="col-12 col-md-6">
                        <img
                            loading="lazy"
                            width="175"
                            height="235"
                            style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                maxHeight: 400,
                                aspectRatio: "9/12",
                                borderRadius: 12,
                                overflow: "hidden",
                            }}
                            src={section?.image.url}
                            alt={section?.image.alt}
                        />
                    </div>
                    <div
                        className={`col-12 col-md-6 d-flex flex-column gap-02`}
                    >
                        <h2 className="text-h3-smbd">{section?.title}</h2>
                        <div className="text-bd2-rg">{section?.content}</div>
                    </div>
                </div>
            </Modal>
        </GlobalAnimation>
    );
}

export default Itinerary;

function ItineraryItem(props: {
    section: any;
    index: number;
    isLast: boolean;
    onClick: () => void;
}) {
    const { section, index, isLast, onClick } = props;
    return (
        <>
            <article
                className={`row gx-2 itinerary_item ${index % 2 === 0 ? "" : "is_right"}`}
            >
                <div className={`col-5 ${index % 2 === 0 ? "" : "order-1"}`}>
                    <img
                        loading="lazy"
                        width="175"
                        height="235"
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            maxHeight: "250px",
                            aspectRatio: "9/12",
                            borderRadius: 12,
                        }}
                        src={section.image.url}
                        alt={section.image.alt}
                    />
                </div>
                <div
                    className={`col-7 d-flex flex-column gap-02 ${index % 2 === 0 ? "align-items-start" : "align-items-end text-end"}`}
                >
                    <h2 className="text-h4-smbd item_title">{section.title}</h2>
                    <div className="text-bd2-rg">{section.content}</div>
                    <Button
                        type="button"
                        label="Ver mais"
                        variant="outlined"
                        customCss="mt-02"
                        onClick={onClick}
                    />
                </div>
            </article>
            {!isLast && (
                <svg>
                    <path
                        fill="none"
                        stroke="#EBE7D0"
                        strokeDasharray="8,8"
                        strokeWidth="2"
                        strokeLinecap="round"
                    />
                    <circle r="5" fill="#EBE7D0" />
                    <circle r="5" fill="#EBE7D0" />
                </svg>
            )}
        </>
    );
}
