import { APP_CONFIGS } from "../../shared/constants";
import GlobalAnimation from "../../components/GlobalAnimation/GlobalAnimation";
import { useFormik } from "formik";
import InputText from "../../components/Forms/InputText/InputText";
import Select from "../../components/Forms/Select/Select";
import Textarea from "../../components/Forms/Textarea/Textarea";
import Button from "../../components/Button/Button";
import { useContext, useEffect, useState } from "react";
import { IGuest } from "../../shared/interfaces/guests.interface";
import * as Yup from "yup";
import { useSendGuest } from "../../shared/hooks/useContactForm";
import { LoadingContext } from "../../shared/providers/loading/loading.provider";
import { LayoutContext } from "../../shared/providers/layout/layout.provider";
import Link from "../../components/Link/Link";
import { routes } from "../../shared/router/routes";
import { useNavigate } from "react-router-dom";

function Checkin() {
    const { setNavPosition } = useContext(LayoutContext);
    const { setIsLoading } = useContext(LoadingContext);
    const navigate = useNavigate();
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [edit, setEdit] = useState<number>();
    const [step, setStep] = useState<number>(1);
    const [guests, setGuests] = useState<IGuest[]>([]);
    const sendGuest = useSendGuest();

    useEffect(() => {
        setNavPosition("right");
    }, []);

    const form = useFormik({
        initialValues: {
            name: "",
            ageGroup: "",
            foodRestrictions: "",
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required("Adicione o nome do convidado"),
            ageGroup: Yup.string().required("Adicione a faixa etária"),
            foodRestrictions: Yup.string(),
        }),
        onSubmit: (values: IGuest) => {
            if (edit) {
                setGuests((p) => {
                    p[edit - 1] = values;
                    return p;
                });
                setEdit(undefined);
            } else {
                setGuests((p) => [...p, values]);
            }
            setStep(1);
            form.resetForm();
        },
    });

    const handleSubmitForm = async () => {
        setIsLoading(true);
        const responses = await Promise.all(
            guests.map((g) => sendGuest.mutateAsync(g)),
        );
        setIsSuccess(!responses.some((resp) => !resp.success));
        setGuests([]);
        setIsLoading(false);
        setStep(3);
    };

    const handleCancelForm = () => {
        if (edit || (!edit && guests.length)) {
            setStep(1);
        } else {
            form.resetForm();
            navigate(routes.HOME.path);
        }
    };

    return (
        <GlobalAnimation>
            <div className="page-checkin">
                <div className="container-fluid gx-0">
                    <div className="row gx-0">
                        <figure className="page_figure col-12 col-md-6">
                            <img
                                onLoad={() => setIsLoading(false)}
                                loading="lazy"
                                width="900"
                                height="1600"
                                src={APP_CONFIGS.pages.CHECKIN.image}
                                alt={APP_CONFIGS.pages.CHECKIN.image_alt}
                            />
                        </figure>
                    </div>
                </div>
                <div className="container page_content">
                    <div className="row h-100">
                        <div className="col-12 col-md-6 col-lg-5 offset-md-6 offset-lg-7 pt-12 pt-md-5 d-flex flex-column gap-1 gap-md-11 page_decoration is_right">
                            <h1 className="text-h2-bd-md text-h3-bd text-uppercase">
                                {APP_CONFIGS.pages.CHECKIN.title}
                            </h1>
                            <p className="text-bd2-md">
                                Adicione os convidados e confirme a sua presença
                                no nosso dia tão especial.
                            </p>
                            <form onSubmit={form.handleSubmit} className="mt-3">
                                {step === 1 && (
                                    <div className="d-flex flex-column gap-3 align-items-start">
                                        {!!guests.length && (
                                            <ul className="guest_list d-flex flex-column w-100 mt-n02">
                                                {guests.map((g, index) => (
                                                    <li
                                                        key={index}
                                                        className="py-02 d-flex justify-content-between align-items-center"
                                                    >
                                                        {g.name}
                                                        <div className="d-flex gap-02">
                                                            <Button
                                                                type="button"
                                                                customCss="px-02"
                                                                icon="create"
                                                                onClick={() => {
                                                                    setEdit(
                                                                        index +
                                                                            1,
                                                                    );
                                                                    setStep(2);
                                                                    form.setValues(
                                                                        g,
                                                                    );
                                                                }}
                                                            />
                                                            <Button
                                                                type="button"
                                                                customCss="px-02"
                                                                icon="clear"
                                                                onClick={() =>
                                                                    setGuests(
                                                                        (p) =>
                                                                            p.filter(
                                                                                (
                                                                                    _,
                                                                                    t,
                                                                                ) =>
                                                                                    t !==
                                                                                    index,
                                                                            ),
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                        <div className="d-flex justify-content-end gap-1 w-100">
                                            <Button
                                                type="button"
                                                label={
                                                    guests.length === 0
                                                        ? "Adicionar convidado"
                                                        : "Adicionar"
                                                }
                                                icon="add"
                                                variant={
                                                    guests.length === 0
                                                        ? "solid"
                                                        : "outlined"
                                                }
                                                customCss={
                                                    guests.length === 0
                                                        ? "mx-auto"
                                                        : ""
                                                }
                                                onClick={() => {
                                                    form.resetForm();
                                                    setStep(2);
                                                }}
                                                isIconRight
                                            />
                                            {!!guests.length && (
                                                <Button
                                                    type="button"
                                                    label="Confirmar"
                                                    icon="check"
                                                    variant="solid"
                                                    onClick={() =>
                                                        handleSubmitForm()
                                                    }
                                                    isIconRight
                                                />
                                            )}
                                        </div>
                                    </div>
                                )}
                                {step === 2 && (
                                    <div className="d-flex flex-column gap-12">
                                        <InputText
                                            type="text"
                                            label="Nome completo"
                                            placeholder="Nome"
                                            name="name"
                                            value={form.values.name}
                                            onChange={form.handleChange}
                                            errors={
                                                form.submitCount > 0
                                                    ? form.errors.name?.split(
                                                          "#",
                                                      )
                                                    : []
                                            }
                                        />
                                        <Select
                                            options={[
                                                {
                                                    label: "Até aos 3 anos",
                                                    value: "-3",
                                                },
                                                {
                                                    label: "3 aos 9 anos",
                                                    value: "3-9",
                                                },
                                                {
                                                    label: "Adultos (+9 anos)",
                                                    value: "adulto",
                                                },
                                            ]}
                                            placeholder="Selecione a sua faixa etária"
                                            value={form.values.ageGroup}
                                            label="Faixa etária"
                                            onChange={(e) =>
                                                form.setFieldValue(
                                                    "ageGroup",
                                                    e.value,
                                                )
                                            }
                                            errors={
                                                form.submitCount > 0
                                                    ? form.errors.ageGroup?.split(
                                                          "#",
                                                      )
                                                    : []
                                            }
                                        />
                                        <Textarea
                                            label="Restrições alimentares"
                                            placeholder="Adicione as suas restrições alimentares"
                                            name="foodRestrictions"
                                            value={form.values.foodRestrictions}
                                            onChange={form.handleChange}
                                        />
                                        <div className="d-flex gap-03 justify-content-end align-items-center">
                                            <Button
                                                type="reset"
                                                label="Cancelar"
                                                variant="outlined"
                                                onClick={handleCancelForm}
                                            />
                                            <Button
                                                type="submit"
                                                label={
                                                    edit
                                                        ? "Atualizar"
                                                        : "Adicionar"
                                                }
                                                variant="solid"
                                                icon="add"
                                                isIconRight
                                            />
                                        </div>
                                    </div>
                                )}
                                {step === 3 && (
                                    <div className="d-flex flex-column justify-content-center align-items-center gap-2">
                                        {isSuccess && (
                                            <>
                                                <i className="icon icon-100 icon-check_circle"></i>
                                                <p className="text-bd2-md text-center">
                                                    A sua confirmação de
                                                    presença foi enviada.
                                                </p>
                                                <Link
                                                    variant="outlined"
                                                    label="Voltar"
                                                    to={routes.HOME.path}
                                                    icon="arrow_back"
                                                />
                                            </>
                                        )}
                                        {!isSuccess && (
                                            <>
                                                <i className="icon icon-100 icon-cancel"></i>
                                                <p className="text-bd2-md text-center">
                                                    Ocorreu um erro no seu
                                                    pedido
                                                </p>
                                                <Button
                                                    type="button"
                                                    variant="outlined"
                                                    icon="arrow_back"
                                                    label="Voltar"
                                                    onClick={() => setStep(1)}
                                                />
                                            </>
                                        )}
                                    </div>
                                )}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </GlobalAnimation>
    );
}

export default Checkin;
